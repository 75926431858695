import { addClass, addCss } from '../lib/ReactUtils'

// margins taken from Menu height
addClass('hero-container', 'relative bg-center bg-cover bg-no-repeat w-full h-screen min-h-100 -mt-24 sm:-mt-32 mb-10 flex justify-center items-center')
addClass('hero-container-app', 'relative bg-center bg-cover bg-no-repeat w-full h-screen flex justify-center items-center')
addClass('hero-overlay', 'z-1 cursor-pointer-none absolute inset-0 bg-indigo-900 opacity-50')
addClass('hero-inner', 'z-2 relative px-4 sm:px-8 max-w-screen-xl mx-auto')
addClass('hero-cols', 'px-4 flex gap-4 justify-between items-center flex-col lg:flex-row')
addClass('hero-col-left', 'w-full sm:w-1/2 flex flex-col items-center lg:block')
addClass('hero-col-right', 'w-full sm:w-1/2 mt-16 lg:mt-0 lg:pl-8')
addClass('hero-text', 'text-center lg:text-left text-3xl lg:text-4xl xl:text-5xl font-bold md:font-black leading-none tracking-tight')
addClass('hero-main', 'text-white')


addCss(`
  .hero-slanted {
    @apply relative text-primary px-8 -mx-4 py-3 mt-5 md:mt-6
  }
  .hero-slanted:before {
    content: "";
    @apply absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10
  }
`)