import React from 'react'
import { useClasses } from '../lib/ReactUtils'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import CTA from '../components/CTA'
import BackgroundImage from 'gatsby-background-image'
import { Link, useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import '../styles/Hero'
import '../styles/TwoCols'
import '../styles/Prose'



export default function ERPPage() {
  const root = useClasses()
  const { heroImage } = useStaticQuery(
    graphql`
      query {
        heroImage: file(
          absolutePath: { glob: "**/images/erp/hero.jpg" }
          sourceInstanceName: { eq: "images" }   
        ) {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 50) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <Layout full dark>
      <SEO title='ERP' description='OneOffice ERP at a glance' image={heroImage.childImageSharp.fluid.src}/>
      <main className='main' ref={root}>

        <BackgroundImage className='hero-container' fluid={heroImage.childImageSharp.fluid}>
          <div className='hero-overlay'/>
          <div className='hero-inner'>
            <div className='hero-text'>
              <div className='hero-main'>Modern Companies ...</div>
              <div className='hero-slanted'>Need Modern Tools</div>
            </div>
          </div>
        </BackgroundImage>

        <div className='container'>
          <div className="heading-separator"/>
          <h1 className="title">OneOffice ERP</h1>
          <h2 className="subtitle">Complete yet Simple</h2>
          <div className="description">
            <p>
              A solid Enterprise Ressources Planning tool is more than just accounting. 
              It merges together all your activities so your team can focus on their core competencies.
            </p>
            <p>
              OOERP is a modern modular document-based ERP that is easy to use and that runs smoothly. 
              No more expensive customizations or training. You will hit the ground running and never look back at the 'old days.'
            </p>
            <p>
              Below are some of the key features of OOERP. This picture cannot be complete in the little space we have here. 
              We encourage to <Link to="/demo">reach out</Link> for a quick demo so we can have all your questions answered.
            </p>
          </div>

          <div className="twocols">
            <div className="twocols-col-image">
              <StaticImage className="twocols-image" src="../images/erp/distribution.jpg" alt="Verticals"/>
            </div>
            <div className="twocols-col-text-left">
              <div className="subheading">#1 - Verticals</div>
              <div className="heading">Key Covered Sectors</div>
              <div className="description">
                <p>OOERP includes all the modules needed by a modern organization.</p>
                <p>We have historically focused on the following verticals in our offerings, which should cover most operations (if you don't fit, please contact us, we can develop at our cost any missing features).</p>
                <div className="prose sm:flex gap-x-2 mt-3">
                  <div className="sm:w-1/2">
                    <ul>
                      <li>Services</li>
                      <li>Retail</li>
                      <li>Education</li>
                      <li>Agriculture</li>
                    </ul>
                  </div>
                  <div className="sm:w-1/2">
                    <ul className='mt-0'>
                      <li>Manufacturing</li>
                      <li>Distribution</li>
                      <li>Non-Profit</li>
                      <li>Healthcare</li>
                    </ul>
                  </div>
                </div>
                <p className="text-gray-700 text-base">
                  Important note - We respectfully decline to support the following industries: gambling, cannabis and any organization encouraging unethical morals or political views.
                </p>
              </div>
            </div>
          </div>


          <div className="twocols">
            <div className="twocols-col-image">
              <StaticImage className="twocols-image" src="../images/erp/accounts-dashboard.png" alt="Accounts Dashboard"/>
            </div>
            <div className="twocols-col-text-right">
              <div className="subheading">#2 - Financial Accounting</div>
              <div className="heading">Counting shouldn't be this fun</div>
              <div className="description">
                <p>OOERP is a full-fledged accounting software. Get a real-time view of your cash flow, books, charts of accounts, banking, invoicing and more. The accounting module covers every aspect of bookkeeping.</p>
                <p className='quote'>The real power comes from deep integrations with other modules. You can properly recognize revenues from your sales, get a snapshot of your inventory, or anything you wish, thanks to easy customizations.</p>
                <p>Reporting is a breeze, with built-in filters and graphs. Good data drives good decision-making.</p>
              </div>
            </div>
          </div>


          <div className="twocols">
            <div className="twocols-col-image">
              <StaticImage className="twocols-image" src="../images/erp/sp-sales-dashboard.png" alt="Sales Dashboard"/>
            </div>
            <div className="twocols-col-text-left">
              <div className="subheading">#3 - Order Management</div>
              <div className="heading">Your customers will <svg xmlns="http://www.w3.org/2000/svg" className="inline h-10 w-10" viewBox="0 0 20 20" fill="currentColor">
    <path fillRule="evenodd" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" clipRule="evenodd" />
  </svg> you!</div>
              <div className="description">
                <p>Increase productivity and lower costs by managing your sales and purchase cycles, from purchase to sales orders.</p>
                <p className='quote'>All critical sales and purchase data are now in one place. Dashboards provide an at-a-glance view of key performance indicators relevant to your business processes.</p>
                <p>Track inventory levels, replenish stock, and manage sales orders, customers, suppliers, shipments, deliverables, and order fulfillment — all in a single sales & purchase ERP.</p>
              </div>
            </div>
          </div>


          <div className="twocols">
            <div className="twocols-col-image">
              <StaticImage className="twocols-image" src="../images/erp/hr.png" alt="HR Dashboard"/>
            </div>
            <div className="twocols-col-text-right">
              <div className="subheading">#4 - HR & Payroll</div>
              <div className="heading">Your employees will <svg xmlns="http://www.w3.org/2000/svg" className="inline h-10 w-10" viewBox="0 0 20 20" fill="currentColor">
    <path fillRule="evenodd" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" clipRule="evenodd" />
  </svg> you too!</div>
              <div className="description">
                <p>Manage full employee life cycle right from onboarding, payroll, attendance, expense claims, assets to separation.</p>
                <p className='quote'>Manage recruitment & attendance, track leaves & expenses, and handle onboarding, training & appraisals. Process payroll painlessly with configurable salary structures & personalized payslips.</p>
                <p>OOERP even lets you manage and share employee multimedia (images, videos and documents).</p>
              </div>
            </div>
          </div>

          <CTA variant={2}/>

          <div className="twocols">
            <div className="twocols-col-image">
              <StaticImage className="twocols-image" src="../images/erp/assets-list-view.png" alt="Assets View"/>
            </div>
            <div className="twocols-col-text-left">
              <div className="subheading">#5 - Inventory & Assets</div>
              <div className="heading">No more guess work</div>
              <div className="description">
                <p>Maintain and Manage details of assets, their movement, value adjustment, and depreciation.</p>
                <p className="quote">From purchase to perishment, IT infrastructure to equipment. Cover every branch of your organization, all in one centralized system.</p>
                <p>Asset lifecycle includes every answer you might need — status of an asset, custodian, location, warranty & insurance, depreciation & depreciation schedule, asset transfer, scraping, selling & movements ... Managing your inventory was never this rewarding.</p>
              </div>
            </div>
          </div>



          <div className="twocols">
            <div className="twocols-col-image">
              <StaticImage className="twocols-image" src="../images/erp/crm.png" alt="CRM"/>
            </div>
            <div className="twocols-col-text-right">
              <div className="subheading">#6 - CRM</div>
              <div className="heading">Relationships matter more than ever</div>
              <div className="description">
                <p>Win and retain more customers by optimizing the sales process. Track leads, opportunities, and send the quotes on the go.</p>
                <p className='quote'>Get full 360 view of your sales process. From leads visibility to pipeline management, sales can become a well-functioning process.</p>
                <p>Automate the tedious marketing & sales tasks, even send quotations with pre-designed templates.</p>
              </div>
            </div>
          </div>


          <div className="twocols">
            <div className="twocols-col-image">
              <StaticImage className="twocols-image" src="../images/erp/projects-task-kanban.png" alt="Kanban Cards"/>
            </div>
            <div className="twocols-col-text-left">
              <div className="subheading">#7 - Projects</div>
              <div className="heading">Sucessful projects <svg xmlns="http://www.w3.org/2000/svg" className="inline h-10 w-10" viewBox="0 0 20 20" fill="currentColor">
    <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
  </svg> profits</div>
              <div className="description">
                <p>Deliver both internal and external projects on time, budget, and profitability. Track tasks, timesheets, and issues by project.</p>
                <p className='quote'>Organize, schedule, plan and analyze projects with a task-driven approach that will streamline team collaboration and accelerate your work.</p>
                <p>Keep track of project deadlines and progress with a clear overview. Plan ahead for upcoming projects, using forecasts based on comparable projects, and estimate deadlines more accurately.</p>
              </div>
            </div>
          </div>


          <div className="twocols">
            <div className="twocols-col-image">
              <StaticImage className="twocols-image" src="../images/erp/production-analytics.png" alt="Production Analytics"/>
            </div>
            <div className="twocols-col-text-right">
              <div className="subheading">#8 - Manufacturing</div>
              <div className="heading">Methodical Craftsmanship</div>
              <div className="description">
                <p>Effectively maintain and manage multilevel bill of materials, production planning, job cards & inventory.</p>
                <p className='quote'>Simplify your production cycle, track material consumption, plan your capacity planning, handle subcontracting, and more!.</p>
                <p>Integrate your production planning and shop floor. Built-in customer management, sales orders, inventory, purchasing, accounting, and financial reporting make real-time coordination across all your business units easier.</p>
              </div>
            </div>
          </div>


          <div className="twocols">
            <div className="twocols-col-image">
              <StaticImage className="twocols-image" src="../images/erp/point-of-sale.png" alt="Point of Sale"/>
            </div>
            <div className="twocols-col-text-left">
              <div className="subheading">#9 - Integrations & Customizations</div>
              <div className="heading">Tools to follow your business</div>
              <div className="description">
                <p>OOERP was designed for easy customizations. In fact, you can do most of it yourself from the User Interface. 99% of the features you need are already there. The rest is child's play.</p>
                <p className='quote'>No two companies are alike. Tools have to adjust to the way you do things, not the other way around.</p>
                <p>We can support most of your customization needs at our expense, from integration with a payment system to interacting with existing legacy solutions.</p>
              </div>
            </div>
          </div>



          <CTA variant={3}/>
        </div>
      </main>
    </Layout>
  )
}
