import React from 'react'
import { Link } from 'gatsby'

const HEADERS = [
  'Start your OneOffice now',
  'Seeing is believing',
  'Your ERP awaits you',
  'Still on the fence?',
  'Your future awaits',
  'No strings attached'
]

const DESCRIPTIONS = [
  'Catapult your digital office! It\'s only 10 mins away',
  'A 15 mins demo will start the revolution',
  'Re-launch your efficient organization now with OOERP',
  'A 10 min online demo will set your mind at ease!',
  'No more hesitation! A demo is worth a million words',
  'Join us for a 15 min demo and decide for yourself'
]

const BUTTONS = [
  'Book your demo',
  'Select slot',
  'Book your demo',
  'Free DEMO',
  'Demo NOW',
  'Demo NOW'
]

const COLORS = [
  ['from-orange-600 to-orange-400', 'white'], // orange
  ['from-amber-400 to-yellow-400', 'white'], // yellow
  ['from-blue-700 to-blue-400', 'white'], // blue
  ['from-violet-900 to-indigo-500', 'white'], // purple
  ['from-green-700 to-lime-400', 'white'], // green
  ['from-red-700 to-rose-400', 'white']  // red
]

export default function({variant = 0, className = ''}) {
  return (
    <div className={'py-14 text-' + COLORS[variant][1] + ' ' + className}>
      <div className={'container relative w-full rounded-lg mx-auto py-12 overflow-hidden bg-gradient-to-br ' + COLORS[variant][0]}>
        <div className="flex flex-col md:flex-row flex-wrap items-center">
          <div className="w-full md:w-2/3">
            <h1 className="uppercase text-center text-2xl md:text-3xl font-extrabold pb-2">
              {HEADERS[variant]}
            </h1>
            <h2 className='text-center text-xl md:text-2xl leading-tight font-thin px-12'>
              {DESCRIPTIONS[variant]}
            </h2>
          </div>
          <div className="w-full flex md:w-1/3 mt-6 md:mt-0 z-10">
            <Link to="/demo" className="button lg cta mx-auto font-bold border border-orange-300">
              {BUTTONS[variant]}
            </Link>
          </div>
        </div>
        <div className={"absolute inset-0 overflow-hidden rounded-lg text-"+COLORS[variant][1]}>
          <svg className={"absolute bottom-0 left-0 w-80 h-80 transform -translate-x-" + Math.floor(30+Math.random()*20) + " translate-y-" + Math.floor(20+Math.random()*20) + " opacity-12"} xmlns="http://www.w3.org/2000/svg" width="600" height="600" viewBox="0 0 600 600" version="1.1">
            <path d="m 476.2,193.1 c 32.5,61.4 25.6,140.4 -10.4,206.3 C 429.9,465.3 365,518.2 290.8,523.5 216.6,528.8 133.1,486.7 98.4,421.4 63.6,356.2 77.4,267.8 119.1,201.1 160.9,134.3 230.4,89.2 301.2,88.5 c 70.7,-0.7 142.6,43.2 175,104.6 z" fill="currentColor"/>
          </svg>
          <svg className={"absolute top-0 right-0 w-80 h-80 transform translate-x-" + Math.floor(30+Math.random()*10) + " -translate-y-" + Math.floor(40+Math.random()*20) + " opacity-9"} xmlns="http://www.w3.org/2000/svg" width="600" height="600" viewBox="0 0 600 600" version="1.1">
            <path d="m 476.2,193.1 c 32.5,61.4 25.6,140.4 -10.4,206.3 C 429.9,465.3 365,518.2 290.8,523.5 216.6,528.8 133.1,486.7 98.4,421.4 63.6,356.2 77.4,267.8 119.1,201.1 160.9,134.3 230.4,89.2 301.2,88.5 c 70.7,-0.7 142.6,43.2 175,104.6 z" fill="currentColor"/>
          </svg>
        </div>
      </div> 
    </div>
  )
}
