import { addCss } from '../lib/ReactUtils'

addCss(`
  .prose {
    @apply text-lg text-gray-800
  }
  .prose p {
    @apply first:mt-0 mt-2 mb-3 tracking-tight
  }
  .prose h1 {
    @apply text-5xl font-black mt-14 mb-5 leading-tight tracking-tight
  }
  .prose h2 {
    @apply text-4xl font-black mt-12 mb-4 leading-tightest tracking-tight
  }
  .prose h3 {
    @apply text-3xl font-semibold mt-8 mb-2 leading-tight tracking-tight
  }
  .prose h4 {
    @apply text-2xl font-semibold mt-6 mb-2 leading-tight tracking-tight
  }
  .prose ul {
    @apply list-disc list-inside
  }
  .prose ol {
    @apply list-decimal	list-inside
  }
  .prose ol:not(.mt-0), ul:not(.mt-0) {
    my-5
  }
  .prose ul li {
    @apply ml-2 mb-1
  }
  .prose ol li {
    @apply ml-1 mb-1
  }
  .prose li p {
    @apply mt-0 inline leading-normal
  }
  .prose thead th {
    @apply text-left text-xl
  }
  .prose tbody tr {
    @apply border-t-2 last:border-b-2 border-gray-200
  }
  .prose td {
    @apply last:pr-0 pr-2 py-2
  }
  .prose blockquote {
    @apply my-5;
  }
  .prose blockquote p {
    @apply py-4 pl-3 pr-2 border-l-4 border-primary bg-blue-100 text-primary-dark font-semibold
  }
  .prose blockquote strong {
    @apply font-black text-orange-700
  }
`)

